import { Outlet } from "react-router-dom"
import ServiceNavigation from "../../components/ServiceNavigation"

const Mortgages = () => {
    return (
        <div>
            <ServiceNavigation sectionKey="mortgages" />
            <Outlet/>
        </div>
    )
}

export default Mortgages