import { useEffect } from "react"

import {
  Routes,
  RouterProvider,
} from "react-router-dom";

import router from './route'

import './App.css';

function App() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <div className="App bg-bexley-gray">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
