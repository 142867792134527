import React, {Component} from "react";
import { useEffect } from "react";

import serviceData from '../../data/services.json'

import listImage from '../../assets/list-image.svg'

type Props = { sectionKey?: string, pageKey?: string } & typeof defaultProps

const defaultProps = { sectionKey: "mortgages", pageKey: "first-time-buyers" }

export default class Service extends Component<Props> {
    static readonly defaultProps = defaultProps;

    componentDidMount = () => {
        window.scrollTo(0,0)
      }
    

    render() {
        const data = JSON.parse(JSON.stringify(serviceData))[this.props.sectionKey][this.props.pageKey]

        const bulletList = [<></>]
        data.bullets.forEach((bullet:string, index:number) => {
            bulletList.push(<li key={"service-bullet-"+index}>{bullet}</li>);
          });

        const image1x = "/assets/images/services/"+this.props.pageKey+".jpg"
        const image1_5x = "/assets/images/services/"+this.props.pageKey+"@1_5x.jpg"
        const image2x = "/assets/images/services/"+this.props.pageKey+"@2x.jpg"
        const image3x = "/assets/images/services/"+this.props.pageKey+"@3x.jpg"

        return (
            <div className="flex flex-col lg:flex-row mt-8">
                { data && 
                    <>
                        <div className="mt-12 lg:mt-0 order-last lg:order-none flex-1 text-center px-8 xl:px-4">
                            <img className="rounded-xl glow-shadow inline-block" src={image1x} srcSet={image1x + ', ' + image1_5x + ' 1.5x, ' + image2x + ' 2x, ' + image3x + ' 3x'} alt={"Image representing '"+data.title+"'"} />
                        </div>
                        <div className="flex-1 font-light">
                            <p className="font-normal mb-4">{data.lead}</p>
                            {data.body.length > 0 &&
                                <p className="mb-4">{data.body}</p>
                            }
                            {data.bullets.length > 0 &&
                                <ul className="w-full list-disc list-inside lg:columns-2 pl-4 mb-4" style={{listStyleImage: "url("+ listImage +")"}}>
                                    {bulletList}
                                </ul>
                            }
                            {data.follow.length > 0 &&
                                <p className="mb-4">{data.follow}</p>
                            }
                        </div>
                    </>
                }
            </div>
        )
    }
}
