import { Link, Outlet } from "react-router-dom";
import ServiceNavigation from "../../components/ServiceNavigation"

const Investments = () => {
    return (
        <div>
            <ServiceNavigation sectionKey="investments" />
            <Outlet/>   
        </div>
    )
}

export default Investments