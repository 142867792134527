import { useEffect } from "react";

import { Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactForm from '../components/ContactForm'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="container mx-auto px-4 pt-20 pb-14">
                    <section>
                        <h1 className="text-4xl font-black text-bexley-blue-dark mb-10">Get in touch</h1>
                        <p className="text-2xl text-bexley-blue-dark mb-10 leading-relaced">Thankyou for your interest in Bexley Financial Solutions. We’d love to speak with you for a free initial conversation.</p>
                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <div className="flex flex-col mb-4 sm:mb-0">
                                    <span className="flex-1 inline-block text-lg text-bexley-blue-light">Phone</span>
                                    <span className="flex-1 inline-block text-2xl">
                                        <a href="tel:+447956830425" target="_blank">+447956 830425</a>
                                    </span>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="flex flex-col">
                                    <span className="flex-1 inline-block text-lg text-bexley-blue-light">Email</span>
                                    <span className="flex-1 inline-block text-2xl">
                                    <a href="mailto:info@bexleyfinancial.co.uk" target="_blank">info@bexleyfinancial.co.uk</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="bg-bexley-gray-light">
                    <div className="container mx-auto px-4 pt-20 pb-14">
                        <ContactForm/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact