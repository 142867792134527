import { useEffect } from "react";

import { Link } from "react-router-dom";

import listImage from '../assets/list-image.svg'
import OurTeamImage from "../components/OurTeamImage";

const OurTeam = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div className="bg-white py-14">
            <div className="container mx-auto py-6 px-4">
                <div className="borded border-b-2 border-bexley-gray w-full">
                    <h2 className="font-medium text-bexley-blue-dark text-4xl border-b-4 border-bexley-green-light inline-block pb-8">Our Company</h2>
                </div>

                <div className="flex mt-8">
                    <div className="flex-1 font-light">
                        <p className="font-normal mb-4">Bexley Financial Services offers a range of financial services by a fully certified financial advisor, with experience and expertise in areas including:</p>
                        <ul className="w-full list-disc list-inside lg:columns-2 pl-4 mb-4" style={{listStyleImage: "url("+ listImage +")"}}>
                            <li>Residential mortgages</li>
                            <li>Commercial mortgages<span className="text-red-600">*</span></li>
                            <li>Buy to let<span className="text-red-600">*</span></li>
                            <li>House of Multiple Occupancy<span className="text-red-600">*</span></li>
                            <li>Right to buy</li>
                            <li>Shared ownership</li>
                            <li>ISAs</li>
                            <li>Guaranteed bonds</li>
                            <li>Pension investments</li>
                            <li>Stocks and shares ISAs</li>
                            <li>Unit trust and OEICS</li>
                            <li>Index trackers</li>
                            <li>Life assurance</li>
                            <li>Critical illness cover</li>
                            <li>Income protection</li>
                            <li>Accident and sickness cover </li>
                            {/* <li className="lg:w-1/2 lg:inline-block"><img src={listImage} className="h-3 align-middle mr-4 inline-block"/>A Service</li> */}
                        </ul>
                        <p className="mb-4">If you have a more specialised need, please get in contact and we can offer a bespoke service to fit your financial needs.</p>
                        <p className="text-small"><span className="text-red-600">*</span>Some Buy to Let mortgages, House in Multiple Occupancy and some Commercial Mortgages are not regulated by the Financial Conduct Authority.</p>
                    </div>
                    <div className="flex-1 text-center hidden lg:block px-8 xl:px-0">
                        <OurTeamImage className="rounded-xl glow-shadow inline-block" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTeam