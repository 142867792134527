import { Link, Outlet } from "react-router-dom";
import ServiceNavigation from "../../components/ServiceNavigation"

const Pensions = () => {
    return (
        <div>
            <ServiceNavigation sectionKey="pensions" />
            <Outlet/>   
        </div>
    )
}

export default Pensions