import { useEffect } from "react";

import { Link } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import PDFImage from '../assets/file-pdf-regular.svg';

const Complaints = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="container mx-auto px-4 pt-20 pb-14">
                    <section>
                        <h1 className="text-4xl font-black text-bexley-blue-dark mb-10">Complaints Policy</h1>
                    </section>
                    <section className="flex flex-col md:flex-row">
                        <div className="flex-1 leading-relaxed">

                            <h2 className="text-2xl font-black">If you are unhappy with our service</h2>
                            <p className="mb-2">If you have a complaint about your adviser, or any financial advice you have received from your adviser, please contact us:</p>

                            <address className="mb-2">
                                Quilter Financial Planning Complaints Department<br/>
                                SUNDERLAND<br/>
                                SR43 4JR
                            </address>

                            <p className="mb-4">
                                <strong>Email</strong>: <a className="underline" href="mailto:QFPcomplaints@quilter.com">QFPcomplaints@quilter.com</a><br/>
                                <strong>Tel</strong>: <a className="underline" href="tel:08081415077">0808 141 5077</a>
                            </p>

                            <p>You can find more information by visiting the Quilter Financial Planning website: <a className="underline" href="http://www.quilterfinancialplanning.co.uk/contacts/" target="_blank">http://www.quilterfinancialplanning.co.uk/contacts/</a></p>
                            <p>If you cannot settle your complaint with us, you may be entitled to refer it to the Financial Ombudsman Service (<a className="underline" href="http://www.financial-ombudsman.org.uk" target="_blank">www.financial-ombudsman.org.uk</a>)</p>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Complaints