import { Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'

const NotFound = () => {
    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="container mx-auto px-4 py-14 text-center">
                    <h1 className="font-black text-4xl text-bexley-blue-dark mb-4">Oops! You seem to be lost.</h1>
                    <p className="mt-6 text-2xl font-light">We cant find the page you have requested, however one of these may be helpful:</p>
                    <div className="controls mt-6 mb-8 xl:mb-6 flex flex-col lg:block text-center">
                        <Link to="/" className="flex-1 bg-bexley-green-light rounded-full text-bexley-blue-dark py-2 px-8 inline-block font-black mb-2 lg:mb-0 lg:mr-4">Return home</Link>
                        <Link to="/services" className="flex-1 bg-bexley-blue rounded-full text-white py-2 px-8 inline-block font-black mb-2 lg:mb-0 lg:mr-4">See our services</Link>
                        <Link to="/contact" className="flex-1 bg-bexley-blue rounded-full text-white py-2 px-8 inline-block font-black">Get in touch</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NotFound