import { useEffect } from "react";

import { Link } from "react-router-dom";

import Header from '../components/Header'
import Footer from '../components/Footer'

import AboutImage from '../components/AboutImage'

const About = () => {
    const opened = new Date(1983, 9, 1)
    const now = new Date()

    let diff =(now.getTime() - opened.getTime()) / 1000;
    diff /= (60 * 60 * 24);

    const years = Math.abs(Math.round(diff/365.25));

    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="container mx-auto px-4 pt-20 pb-14">
                    <section>
                        <h1 className="text-4xl font-black text-bexley-blue-dark mb-10">Bespoke financial advice for piece of mind.</h1>
                        <p className="text-2xl text-bexley-blue-dark mb-10 leading-relaced">Our advice is backed by the Quilter Financial Planning Network, which means we're better suited to help you achieve your financial goals.</p>
                    </section>
                    <section className="flex flex-col md:flex-row">
                        <div className="flex-1 leading-relaxed">
                            <p className="mb-6">Billy Meares has worked in the financial services industry for more than {years} years with a wealth of experience in many aspects of financial planning and mortgage advice. He first became a financial adviser back in 1996, and continues to look after the same clients from those early days, now helping their families financial and mortgage requirements, offering long term advice and support.</p>
                            <p>Billy is highly qualified, with a certificate in mortgage advice (CeMAP) and a diploma in financial planning (DipFA) and meets the exacting standards set by our industry. He has a passion for maintaining his knowledge in an ever changing landscape.</p>
                            <div className="controls mt-6 mb-8 xl:mb-6 flex flex-col lg:flex-row text-center">
                                <Link to="/services" className="flex-1 bg-bexley-green-light rounded-full text-bexley-blue-dark py-2 px-8 inline-block font-black mb-2 lg:mb-0 lg:mr-4">See our services</Link>
                                <Link to="/contact" className="flex-1 bg-bexley-blue rounded-full text-white py-2 px-8 inline-block font-black">Contact us</Link>
                            </div>
                        </div>
                        <div className="flex-1 mt-6 md:mt-0 text-center px-8 xl:px-0">
                            <AboutImage className="rounded-xl glow-shadow inline-block" />
                        </div>
                    </section>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default About