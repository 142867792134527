import {
  createBrowserRouter,
  RouteObject,
  Navigate
} from 'react-router-dom';

import NotFound from './routes/NotFound'
import Root from './routes/Root'
import OurTeam from './routes/OurTeam'
import About from './routes/About'
import Services from './routes/Services'
import Mortgages from './routes/services/Mortgages'
import Investments from './routes/services/Investments'
import Insurance from './routes/services/Insurance'
import Pensions from './routes/services/Pensions'
import Service from './routes/services/Service'
import Contact from './routes/Contact'
import Privacy from './routes/Privacy'
import Complaints from './routes/Complaints'

import serviceData from './data/services.json'

const buildChildrenFor = (key: string): RouteObject[] => {
  const data = serviceData[key as keyof typeof serviceData]
  const keys = Object.keys(data)

  console.log(key)
  console.log(data)
  console.log(keys)
  console.log(keys[0])

  let routes:RouteObject[] = [{index: true, element: <Navigate replace to={"/services/"+key+"/"+keys[0]} />}]

  for(let pageKey in keys) {
    console.log(keys[pageKey])
    const route = {
      path: keys[pageKey],
      element: <Service sectionKey={key} pageKey={keys[pageKey]} />
    }

    routes.push(route)
  }

  return routes
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <OurTeam />,
      },
    ]
  },
  {
      path: '/about',
      element: <About />,
  },
  {
      path: '/services',
      element: <Services />,
      children: [
        {
          index: true,
          element: <Navigate replace to="/services/mortgages" />
        },
        {
          path: 'mortgages',
          element: <Mortgages />,
          children: buildChildrenFor('mortgages')
        },
        {
          path: 'investments',
          element: <Investments />,
          children: buildChildrenFor('investments')
        },
        {
          path: 'pensions',
          element: <Pensions />,
          children: buildChildrenFor('pensions')
        },
        {
          path: 'insurance',
          element: <Insurance />,
          children: buildChildrenFor('insurance')
        },
      ]
  },
  {
      path: '/contact',
      element: <Contact />,
  },
  {
      path: '/privacy',
      element: <Privacy />,
  },
  {
      path: '/complaints',
      element: <Complaints />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]


// {
//   path: '/services',
//   element: <Services />,
//   children: [
//     {
//       index: true,
//       element: <Navigate replace to="/services/mortgages" />
//     },
//     {
//       path: 'mortgages',
//       element: <Mortgages />,
//       children: [
        
//       ]
//     },
//     {
//       path: 'investments',
//       element: <Investments />,

//     },
//     {
//       path: 'pensions',
//       element: <Pensions />,

//     },
//     {
//       path: 'insurance',
//       element: <Pensions />,

//     },
//   ]
// },

const router = createBrowserRouter(routes)

export default router