import React, {Component} from "react";

import image1x from '../assets/about/about.jpg'
import image1_5x from '../assets/about/about@1,5x.jpg'
import image2x from '../assets/about/about@2x.jpg'
import image3x from '../assets/about/about@3x.jpg'

type Props = { className?: string } & typeof defaultProps

const defaultProps = { className: "" }

class AboutImage extends Component<Props> {
    static readonly defaultProps = defaultProps;

    render = () =>{
        return (
            <img className={this.props.className} src={image1x} srcSet={image1x + ', ' + image1_5x + ' 1.5x, ' + image2x + ' 2x, ' + image3x + ' 3x'} alt="Image of someone typing on a laptop" />
        )
    } 
}

export default AboutImage