import React, {Component} from "react";
import { Link } from "react-router-dom";

import logoMark from "../assets/logo-mark-white.svg"

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <section className="bg-bexley-blue text-white font-light flex py-10">
                    <div className="container mx-auto flex py-6 px-4">
                        <div className="grow">
                            <p className="mt-3">
                                &copy; {(new Date().getFullYear())} <Link to="/">Bexley Financial Solutions</Link>
                            </p>
                        </div>
                        <div className="grow-0">
                            <Link to="/">
                                <img src={logoMark} className="h-10" />
                            </Link>
                        </div>

                        <div className="grow h-100 pt">
                            <nav>
                                <ul className="flex justify-end h-100 align-middle">
                                    <li className="mr-10 mt-3 md:mt-2 h-100 align-middle"><Link to="/privacy">Privacy Notice</Link></li>
                                    <li className="mr-10 mt-3 md:mt-2 h-100 align-middle"><Link to="/complaints">Complaints Policy</Link></li>
                                    <li className="hidden md-1 md:inline-block">
                                        <Link to="/contact">
                                            <span className="bg-bexley-green rounded-full text-bexley-blue-dark px-10 py-2 inline-block">Get in touch</span>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="text-center">
                    <div className="container mx-auto my-4 font-light text-sm leading-relaxed">
                        <p>Bexley Financial Solutions Limited: Registered in England & Wales no 06640620. Registered address: 9 LImes Road, Beckenham, Kent, United Kingdom. BR3 ONS</p>
                        <p>Bexley Financial Solutions is an appointed representative of Quilter Financial Services Limited and Quilter Mortgage Planning Limited which are authorised and regulated by the Financial Conduct Authority.</p>
                        <p>The guidance and/or information contained within the website is subject to the UK regulator regime and is therefore primarily targeted at customers in the UK.</p>
                    </div>
                </section>
            </footer>
        )
    }
}
