import React, {Component} from "react";

import image1x from '../assets/home/homeHeaderImage.jpg'
import image1_5x from '../assets/home/homeHeaderImage@1.5x.jpg'
import image2x from '../assets/home/homeHeaderImage@2x.jpg'

type Props = { className?: string } & typeof defaultProps

const defaultProps = { className: "" }

class HomeHeaderImage extends Component<Props> {
    static readonly defaultProps = defaultProps;

    render = () =>{
        return (
            <img className={this.props.className} src={image1x} srcSet={image1x + ', ' + image1_5x + ' 1.5x, ' + image2x + ' 2x'} alt="Image of a dusk office building with some windows illuminated" />
        )
    } 
}

export default HomeHeaderImage