import React, {Component} from "react"
import { NavLink, Outlet } from "react-router-dom"
import serviceData from '../data/services.json'

type Props = { sectionKey?: string } & typeof defaultProps

const defaultProps = { sectionKey: "mortgages" }

export default class ServiceNavigation extends Component<Props> {
    static readonly defaultProps = defaultProps;

    render() {
        const data = JSON.parse(JSON.stringify(serviceData))[this.props.sectionKey]
        const keys = Object.keys(data)

        const baseNavClass = "rounded-lg lg:rounded-none py-4 lg:py-0 lg:pb-8 w-full lg:w-auto text-center lg:text-left inline-block"
        const activeNavClass = " bg-bexley-green-light lg:bg-transparent lg:border-b-4 border-bexley-green-light"
        const activeClassNameFunction = ({isActive}: {isActive?: boolean}) => isActive ?  baseNavClass+activeNavClass : baseNavClass

        let navigationLinks = [<></>]
        keys.forEach((key, index) => {
            const serviceData = data[key]
            navigationLinks.push(<li className="font-medium text-bexley-blue-dark text-2xl w-full lg:w-auto lg:text-3xl inline-block mr-12" key={index}><NavLink className={activeClassNameFunction} to={key}>{serviceData.title}</NavLink></li>);
        });

        return(
            <ul className="lg:border-b-2 border-bexley-gray w-full">
                {navigationLinks}
            </ul>
        )
    }
}