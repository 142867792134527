import React, {Component} from "react";
import { Link, NavLink } from "react-router-dom";

import logo  from "../assets/logo-full-white.svg";

type State = typeof initialState;
type Props = { className?: string } & typeof defaultProps

const initialState = { navOpen: false }
const defaultProps = { className: "bg-bexley-blue-dark" }

export default class Header extends Component<Props> {
    static readonly defaultProps = defaultProps;
    state = initialState;

    render() {
        const activeNavClass = "text-bexley-green"
        const activeClassNameFunction = ({isActive}: {isActive?: boolean}) => isActive ? activeNavClass : undefined
        const classNameValue = this.props.className ? this.props.className : defaultProps.className

        const setNavOpenState = (openValue?: boolean): void => {
            this.setState((_state, _props) => ({ navOpen: openValue }))
        }

        return (
            <header className={classNameValue + " text-white text-lg font-light"}>
                <div className="container mx-auto flex py-6 px-4">
                    <div className="flex-none">
                        <Link to="/">
                            <img src={logo} alt="Bexley Financial Solutions" className="h-20" />
                        </Link>
                    </div>
                    
                    <div className="grow h-100">
                        <section className="mobile-menu flex lg:hidden">
                            <div className="hamburger-icon p-2 rounded bg-bexley-blue-dark border border-bexley-blue-light absolute top-0 right-0 mt-6 mr-6" onClick={() => setNavOpenState(!this.state.navOpen)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
                                </svg>
                            </div>

                            <div className={this.state.navOpen ? "showMenuNav block absolute top-0 left-0 bg-bexley-blue-dark border-b-2 border-bexley-blue-light" : "hidden"}>
                                <div
                                    className="CROSS-ICON absolute top-0 right-0 mt-2 mr-2 px-6 py-6"
                                    onClick={() => setNavOpenState(false)} // change isNavOpen state to false to close the menu
                                >
                                    <svg
                                        className="h-8 w-8 text-gray-600"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </div>
                                <ul className="mobile-menu-open flex flex-col items-center justify-between min-h-[250px] bg-bexley-blue-dark">
                                    <li className="border-b border-gray-400 hover:border-bexley-green my-8 uppercase">
                                        <NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/">Home</NavLink>
                                    </li>
                                    <li className="border-b border-gray-400 hover:border-bexley-green my-8 uppercase">
                                        <NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/about">About</NavLink>
                                    </li>
                                    <li className="border-b border-gray-400 hover:border-bexley-green my-8 uppercase">
                                        <NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/services">Services</NavLink>
                                    </li>
                                    <li className="border-b border-gray-400 hover:border-bexley-green my-8 uppercase">
                                        <NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/contact">Get in touch</NavLink>
                                    </li>
                                </ul>
                                </div>
                        </section>

                        <nav className="desktop-menu hidden lg:block">
                            <ul className="flex justify-end h-100 align-middle mt-4">
                                <li className="mr-10 mt-2 h-100 align-middle"><NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/">Home</NavLink></li>
                                <li className="mr-10 mt-2 h-100 align-middle"><NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/about">About</NavLink></li>
                                <li className="mr-10 mt-2 h-100 align-middle"><NavLink className={activeClassNameFunction} onClick={() => setNavOpenState(!this.state.navOpen)} to="/services">Services</NavLink></li>
                                <li>
                                    <Link to="/contact">
                                        <span className="bg-bexley-green rounded-full text-bexley-blue-dark py-2 px-10 inline-block">Get in touch</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <style>{`
                    .hideMenuNav {
                        display: none;
                    }
                    .showMenuNav {
                        width: 100%;
                        height: 100vh;
                        z-index: 10;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                `}</style>
            </header>
        )
    }
}
