import { useEffect } from "react";

import { Link, Outlet } from "react-router-dom";
import ServiceNavigation from "../../components/ServiceNavigation"

const Insurance = () => 
{
    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div>
            <ServiceNavigation sectionKey="insurance" />
            <Outlet/>   
        </div>
    )
}

export default Insurance