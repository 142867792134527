import { Link, NavLink, Outlet } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'

import bgMobile from '../assets/services/servicesHeader.jpg'
import bgMobile2x from '../assets/services/servicesHeader@2x.jpg'
import bgMobile3x from '../assets/services/servicesHeader@3x.jpg'
import bgLg from '../assets/services/servicesHeaderLG.jpg'
import bgLg2x from '../assets/services/servicesHeaderLG@2x.jpg'
import bgLg3x from '../assets/services/servicesHeaderLG@3x.jpg'
import bgXl from '../assets/services/servicesHeaderXL.jpg'
import bgXl2x from '../assets/services/servicesHeaderXL@2x.jpg'
import bgXl3x from '../assets/services/servicesHeaderXL@3x.jpg'

const Services = () => {
    const navClass = "border-b text-center lg:text-left lg:border-b-0 border-white text-2xl lg:text-4xl px-12 lg:px-0 py-4 mr-16 font-medium inline-block w-full lg:w-auto  "
    const inactiveNavClass = "text-white lg:text-bexley-blue-light"
    const activeNavClass = "rounded-lg lg:rounded-none text-bexley-blue-dark lg:text-bexley-green bg-bexley-green-light lg:bg-transparent lg:border-t-4 lg:border-bexley-green-light"
    const activeClassNameFunction = ({isActive}: {isActive?: boolean}) => isActive ? navClass + activeNavClass : navClass + inactiveNavClass

    return (
        <div>
            <div className="bg-bexley-blue-dark text-white bg-no-repeat bg-top bg-cover bg-services-header">
                <Header className="bg-transparent" />
                <div className="container mx-auto px-4 flex pt-10 pb-10 flex-col">
                    <h1 className="flex-1 w-full text-3xl lg:text-5xl font-black leading-relaxed">Tailor-made solutions designed to meet your most challenging financial needs.</h1>
                    <div className="flex-1 controls mt-12 mb-6 lg:mb-20 xl:mb-6 w-full lg:border-t-2" style={{borderColor: 'rgba(218, 218, 218, 0.33)'}}>
                        <NavLink className={activeClassNameFunction} to="mortgages">Mortgages</NavLink>
                        <NavLink className={activeClassNameFunction} to="investments">Investments</NavLink>
                        <NavLink className={activeClassNameFunction} to="pensions">Pensions</NavLink>
                        <NavLink className={activeClassNameFunction} to="insurance">Insurance</NavLink>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="container mx-auto px-4 pt-20 pb-14">
                    <h2 className="hidden lg:inline-block text-4xl leading-relaxed w-full mb-10">At Bexley Financial Solutions we provide our clients with bespoke advice for piece of mind today, and in the future.</h2>
                    <Outlet/>   
                </div>
            </div>
            <style>
            {`
                .bg-services-header {
                    background-image: url( '`+ bgMobile + `' );
                }
                @media 
                    only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
                    only screen and (    min--moz-device-pixel-ratio: 1.3 ),
                    only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
                    only screen and (         min-device-pixel-ratio: 1.3 ),
                    only screen and ( min-resolution: 124.8dpi ),
                    only screen and ( min-resolution: 1.3dppx ) {

                    .bg-services-header {
                        background-image: url( '`+ bgMobile2x + `' );
                    }

                }
                /* @3x Images (Pixel Ratio of 2.25+) */
                @media 
                    only screen and (-o-min-device-pixel-ratio: 9/4),
                    only screen and (-webkit-min-device-pixel-ratio: 2.25),
                    only screen and (min-device-pixel-ratio: 2.25),
                    only screen and (min-resolution: 2.25dppx) {

                        .bg-services-header {
                            background-image: url( '`+ bgMobile3x + `' );
                        }

                }

                @media (min-width: 1024px) {
                    .bg-services-header {
                        background-image: url( '`+ bgLg + `' );
                    }
                    @media 
                        only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
                        only screen and (    min--moz-device-pixel-ratio: 1.3 ),
                        only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
                        only screen and (         min-device-pixel-ratio: 1.3 ),
                        only screen and ( min-resolution: 124.8dpi ),
                        only screen and ( min-resolution: 1.3dppx ) {
    
                        .bg-services-header {
                            background-image: url( '`+ bgLg2x + `' );
                        }
    
                    }
                    /* @3x Images (Pixel Ratio of 2.25+) */
                    @media 
                        only screen and (-o-min-device-pixel-ratio: 9/4),
                        only screen and (-webkit-min-device-pixel-ratio: 2.25),
                        only screen and (min-device-pixel-ratio: 2.25),
                        only screen and (min-resolution: 2.25dppx) {
    
                            .bg-services-header {
                                background-image: url( '`+ bgLg3x + `' );
                            }
    
                    }
                }

                @media (min-width: 1536px) {
                    .bg-services-header {
                        background-image: url( '`+ bgXl + `' );
                    }
                    @media 
                        only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
                        only screen and (    min--moz-device-pixel-ratio: 1.3 ),
                        only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
                        only screen and (         min-device-pixel-ratio: 1.3 ),
                        only screen and ( min-resolution: 124.8dpi ),
                        only screen and ( min-resolution: 1.3dppx ) {
    
                        .bg-services-header {
                            background-image: url( '`+ bgXl2x + `' );
                        }
    
                    }
                    /* @3x Images (Pixel Ratio of 2.25+) */
                    @media 
                        only screen and (-o-min-device-pixel-ratio: 9/4),
                        only screen and (-webkit-min-device-pixel-ratio: 2.25),
                        only screen and (min-device-pixel-ratio: 2.25),
                        only screen and (min-resolution: 2.25dppx) {
    
                            .bg-services-header {
                                background-image: url( '`+ bgXl3x + `' );
                            }
    
                    }
                }
                `}
            </style>
            <Footer />
        </div>
    )
}

export default Services