// @ts-nocheck
import React, {Component, ChangeEvent, FormEvent} from "react";


const initialState = Object.freeze({
    inputEmail: '',
    inputMessage: '',
    inputName: '',
    inputTelephone: '',
    emailValid: false,
    telephoneValid: false,
    isValidationErrorShown: false,
    isFormValid: false,
    isSuccessMessageShown: false,
    showError: false,
    errorMessage: '',
    sendingMessage: false
  })

type State = typeof initialState;

export default class ContactForm extends Component {
    state = {
        inputEmail: '',
        inputMessage: '',
        inputName: '',
        inputTelephone: '',
        inputPrivacyAccepted: false,
        emailValid: false,
        telephoneValid: false,
        isValidationErrorShown: false,
        isFormValid: false,
        isSuccessMessageShown: false,
        showError: false,
        errorMessage: '',
        sendingMessage: false
      }

    // Handle visitor's interaction with inputs
    handleInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        // Test for input and length of the value
        // if (event.target.value.length > 0) {
            this.setState({
                [event.target.name]: event.target.value
            })
        // }

        // If input is for email address validate it with regexp
        if (event.target.name === 'inputEmail') {
            // eslint-disable-next-line
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (reg.test(String(event.target.value).toLowerCase())) {
                this.setState({
                    emailValid: true
                })
            } else {
                this.setState({
                    emailValid: false
                })
            }
        }

        if (event.target.name === 'inputTelephone') {
            // eslint-disable-next-line
            const reg = /^(\d{10}\d*)$|^(\+44\d{9}\d*)$/

            const whitespaceRemovedValue = event.target.value.replace(/\s/g, "")
            if (reg.test(String(whitespaceRemovedValue))) {
                this.setState({
                    telephoneValid: true
                })
            } else {
                this.setState({
                    telephoneValid: false
                })
            }
        }

        if (event.target.name === 'inputPrivacyAccepted') {
            let value = event.target.checked

            this.setState({
                inputPrivacyAccepted: value
            })
        }

        if(this.inputValid()) {
            this.setState({
                isValidationErrorShown: false,
                isFormValid: true
            })
        }
    }

    validFields = () => {
        return (
            this.state.inputEmail.length > 0 && 
            this.state.inputName.length > 0 && 
            this.state.inputTelephone.length > 0 && 
            this.state.inputMessage.length > 0 && 
            this.state.emailValid && 
            this.state.telephoneValid
        )
    }

    inputValid = () => {
        const validFields = this.validFields()

        const policyAcceptance = this.state.inputPrivacyAccepted
        
        return (validFields && policyAcceptance)
    }

    handleFormSubmit = (event: FormEvent) => {
        event.preventDefault()

        // Test
        if (this.inputValid()) {
            this.setState({
                isValidationErrorShown: false,
                isFormValid: true,
                showError: false,
                errorMessage: "",
                sendingMessage: true
            })

            const requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: this.state.inputName, email: this.state.inputEmail, telephone: this.state.inputTelephone, message: this.state.inputMessage })
            };
            fetch('https://contact.bexleyfinancial.co.uk/contact/', requestOptions)
                .then(async response => {
                    const status = response.status

                    if(status >= 500) {
                        this.setState({
                            showError: true,
                            errorMessage: "Sorry, there was a problem sending the message. The contact form is not currently available, please call or email us directly instead. We apologise for the inconvenience."
                        })
                    } else if(status != 200) {
                        this.setState({
                            showError: true,
                            errorMessage: "Sorry, there was an error sending the message. Please try again."
                        })
                    } else {
                        // Reset state after sending the form
                        this.setState({
                            inputEmail: '',
                            inputMessage: '',
                            inputName: '',
                            inputTelephone: '',
                            inputPrivacyAccepted: false,
                            emailValid: false,
                            telephoneValid: false,
                            isValidationErrorShown: false,
                            isFormValid: false,
                            isSuccessMessageShown: true,
                            showError: false,
                            errorMessage: '',
                            sendingMessage: false
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        showError: true,
                        errorMessage: "Sorry, there was a problem sending the message. The contact form is not currently available, please call or email us directly instead. We apologise for the inconvenience.",
                        sendingMessage: false
                    })
                    console.error('There was an error!', error);
                });
        } else {
            // Show error message
            this.setState({
                isValidationErrorShown: true,
                isFormValid: false
            })
        }
    }
    
    handleToggle = (event: FormEvent) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name == "inputPrivacyAccepted")
            value = (value == "on")
            
        this.setState({
            [name]: value
        });
    }

    render() {
        const submitButtonText = this.state.sendingMessage ? "Sending..." : "Submit"
        const submitButtonBackground = this.state.sendingMessage ? "bg-bexley-gray" : "bg-bexley-green-light"

        return(
            <>
                <form action="">
                    { this.state.isValidationErrorShown && 
                        <div className="mx-4 px-4 py-2 mb-4 rounded-lg bg-red-300 border border-red-700 text-red-900">
                            { this.validFields() === false && 
                                <div>
                                    <p>Please enter a valid:</p>
                                    <ul className="font-bold list-disc  list-inside">
                                        { this.state.inputName.length == 0 && 
                                            <li>Name</li>
                                        }
                                        { !this.state.emailValid && 
                                            <li>Email</li>
                                        }
                                        { !this.state.telephoneValid && 
                                            <li>Telephone</li>
                                        }
                                        { this.state.inputMessage.length == 0 && 
                                            <li>Message</li>
                                        }
                                    </ul>
                                </div>
                            }
                            { this.state.inputPrivacyAccepted === false &&
                                <p className="font-bold"> Please accept the privacy policy</p>
                            }
                        </div>
                    }
                    { this.state.isSuccessMessageShown &&
                        <div className="mx-4 px-4 py-2 mb-4 rounded-lg bg-green-300 border border-green-700 text-green-900">
                            <p>Your message has been successfully sent. Thank you.</p>
                        </div>
                    }
                    { this.state.showError &&
                        <div className="mx-4 px-4 py-2 mb-4 rounded-lg bg-red-300 border border-red-700 text-red-900">
                            <p><strong className={"font-bold"}>Error:</strong> {this.state.errorMessage}</p>
                        </div>
                    }
                    <div className="flex flex-col lg:flex-row">
                        <div className="flex-1 pl-4 pr-4 lg:pr-2">
                            <fieldset>
                                <label className="sr-only" htmlFor="inputName">Name</label>

                                <input onChange={this.handleInput} type="text" name="inputName" id="inputName" required={true} placeholder="Name*" className="w-full px-4 py-2 border-2 border-bexley-gray" value={this.state.inputName} />
                            </fieldset>
                        </div> 
                        <div className="flex-1 px-4 lg:px-2 pt-4 lg:pt-0">
                            <fieldset>
                                <label className="sr-only" htmlFor="inputEmail">Email</label>

                                <input onChange={this.handleInput} type="email" name="inputEmail" id="inputEmail" required={true} placeholder="Email*" className="w-full px-4 py-2 border-2 border-bexley-gray" value={this.state.inputEmail} />
                            </fieldset>
                        </div>  
                        <div className="flex-1 pl-4 lg:pl-2 pr-4 pt-4 lg:pt-0">
                            <fieldset>
                                <label className="sr-only" htmlFor="inputTelephone">Telephone</label>

                                <input onChange={this.handleInput} type="tel" name="inputTelephone" id="inputTelephone" required={true} placeholder="Telephone*" minLength={10} className="w-full px-4 py-2 border-2 border-bexley-gray" value={this.state.inputTelephone} />
                            </fieldset>
                        </div>                    
                    </div>
                    <div className="flex px-4 pt-4">
                        <fieldset className="w-full">
                            <label className="sr-only" htmlFor="inputMessage">Message</label>

                            <textarea onChange={this.handleInput} name="inputMessage" id="inputMessage" required={true} placeholder="Message*" className="w-full px-4 py-2 border-2 border-bexley-gray" value={this.state.inputMessage}></textarea>
                        </fieldset>
                    </div>
                    <div className="flex px-4 pt-4">
                        <fieldset className="w-full">
                            <input onChange={this.handleInput} name="inputPrivacyAccepted" id="inputPrivacyAccepted" required={true} type="checkbox" className="px-4 mr-2 py-2 border-2 border-bexley-gray" checked={this.state.inputPrivacyAccepted}/>
                            <label htmlFor="inputPrivacyAccepted">Accept <a href="/privacy" target="_blank" className="font-bold underline hover:text-bexley-blue">privacy policy</a>.</label>
                        </fieldset>
                    </div>
                    <div className="px-4 pt-4">
                        <button onClick={this.handleFormSubmit} type="button" name="inputSubmit" id="inputSubmit" className={submitButtonBackground + " rounded-full text-bexley-blue-dark py-2 px-8 inline-block font-black sm:mr-4"} disabled={this.state.sendingMessage}>{submitButtonText}</button>
                    </div>
                </form>
            </>
        )
    }
}