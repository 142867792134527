import { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

import Header from '../components/Header'
import Footer from '../components/Footer'
import HomeHeaderImage from "../components/HomeHeaderImage";

import headerLogoMark from '../assets/header-logo-mark.svg';
import arrow from '../assets/arrow.svg'

const Root = () => {
    const opened = new Date(1983, 9, 1)
    const now = new Date()

    let diff =(now.getTime() - opened.getTime()) / 1000;
    diff /= (60 * 60 * 24);

    const years = Math.abs(Math.round(diff/365.25));

    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div>
            <div className="bg-bexley-blue-dark text-white bg-no-repeat bg-right-offset bg-50vh" style={{backgroundImage: "url(" + headerLogoMark + ")"}}>
                <Header className="bg-transparent" />
                <div className="container mx-auto px-4 flex mt-10">
                    <HomeHeaderImage className="flex-none md:w-1/3 rounded-t-xl hidden xl:block" />
                    <div className="grow px-8 lg:px-14">
                        <h1 className="text-4xl font-black leading-relaxed">Your partner for mortgages, insurance and protection. We work with you to find the most suitable solution to your financial needs, both today and for the future.</h1>
                        <p className="mt-6 text-2xl font-light">With over {years} years of experience in finding the most suitable products for our clients, Bexley Financial Services is a great choice for your personal or business needs.</p>
                        <div className="controls mt-6 mb-20 xl:mb-6">
                            <Link to="services" className="bg-bexley-green-light rounded-full text-bexley-blue-dark py-2 px-8 inline-block font-black sm:mr-4">See our services</Link>
                            <span className="hidden sm:inline-block font-light">Bespoke advice for piece of mind.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-bexley-blue text-white pt-16 pb-10 lg:pb-16">
                <div className="container mx-auto px-4">
                    <p className="text-2xl font-light">
                        Bexley Financial Services Limited is both a mortgage and financial advisement firm based in and around Bexley, south-east London.
                    </p>    
                    <h2 className="text-4xl font-black leading-relaxed mt-8">Trusted by our clients since {opened.getFullYear()} to provide</h2>
                    <div className="lg:hidden mt-4">
                        <Link className="block w-full mb-4 px-6 py-8 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg" to="services/pensions">
                            <div className="flex">
                                <div className="grow">Personal and company pensions</div>
                                <div className="grow-0"><img src={arrow} className="w-6"/></div>
                            </div>
                        </Link>
                        <Link className="block w-full mb-4 px-6 py-8 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg" to="services/investments">
                            <div className="flex">
                                <div className="grow">Investments</div>
                                <div className="grow-0"><img src={arrow} className="w-6"/></div>
                            </div>
                        </Link>
                        <Link className="block w-full py-8 px-6 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg" to="services/mortgages">
                            <div className="flex">
                                <div className="grow">Mortgages</div>
                                <div className="grow-0"><img src={arrow} className="w-6"/></div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="hidden lg:flex container mx-auto px-4 mt-8 negative-bottom">
                    <Link className="flex-1 mr-6 px-6 py-8 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg" to="services/pensions">
                        <div className="flex">
                            <div className="grow">Personal and company pensions</div>
                            <div className="grow-0"><img src={arrow} className="w-6"/></div>
                        </div>
                    </Link>
                    <Link className="flex-1 mr-6 px-6 py-8 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg" to="services/investments">
                        <div className="flex">
                            <div className="grow">Investments</div>
                            <div className="grow-0"><img src={arrow} className="w-6"/></div>
                        </div>
                    </Link>
                    <Link className="flex-1 px-6 py-8 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg" to="services/mortgages">
                        <div className="flex">
                            <div className="grow">Mortgages</div>
                            <div className="grow-0"><img src={arrow} className="w-6"/></div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="bg-white lg:pt-6">
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default Root 