import { useEffect } from "react";

import { Link } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import PDFImage from '../assets/file-pdf-regular.svg';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
      },[])

    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="container mx-auto px-4 pt-20 pb-14">
                    <section>
                        <h1 className="text-4xl font-black text-bexley-blue-dark mb-10">Privacy Policy</h1>
                    </section>
                    <section className="flex flex-col md:flex-row">
                        <div className="flex-1 leading-relaxed">
                            <div className="controls mt-6 mb-8 xl:mb-6 flex flex-col lg:flex-row text-center">
                                <a href="/website-privacy-notice-2023.pdf" download={true} target="_blank" className="block w-3/6 mb-4 px-6 py-8 font-black bg-bexley-green-light hover:bg-bexley-green text-bexley-blue rounded-lg">
                                    <div className="flex">
                                        <div className="grow-0"><img src={PDFImage} className="w-6"/></div>
                                        <div className="grow">Privacy Policy Download</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy